import React from 'react'
import './Styles-CustomerDiscovery/LargeIconButtonPanel.css'
import { Paper, Typography, Button } from '@material-ui/core'
import { grey, purple } from '@material-ui/core/colors'
import { colors } from '../ux/roviTheme'
import IconStack from '../IconStack'
import { IStandardIcons } from '../ux/standardIcons'
import InfoHover from '../InfoHover'
import loadable from '@loadable/component'

const WidthViewport = loadable(() => import('../ux/WidthViewport'))

interface IState {
  buttonSelected: boolean
}

interface IProps {
  text?: string
  variant: 'large' | 'small'
  info?: string
  onClick?: (bool: boolean | string) => void
  icons?: IStandardIcons[]
  /** Used for the small variant Icon */
  value?: string
  selected?: boolean
}

class SmallIconButtonPanel extends React.Component<IProps, IState> {
  state: IState = {
    buttonSelected: false,
  }
  render() {
    const small = this.props.variant === 'small'
    const large = this.props.variant === 'large'
    const selected = this.props.selected
      ? this.props.selected
      : this.props.value
      ? this.props.value === this.props.text
        ? true
        : false
      : this.state.buttonSelected
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <Paper
            className="libpContainer"
            style={{
              height: small ? (isMobile ? 50 : 70) : isMobile ? 120 : 180,
              width: small ? (isMobile ? 150 : 250) : '100%',
              maxWidth: small ? 250 : 400,
              borderColor: grey[300],
            }}
            elevation={selected ? 3 : 0}
            onClick={() => {
              this.props.onClick(
                small ? this.props.text : !this.state.buttonSelected
              )
              this.setState({ buttonSelected: !this.state.buttonSelected })
            }}
          >
            {selected ? (
              <div
                style={{
                  backgroundColor: colors.primaryOrange,
                }}
                className="fade"
              />
            ) : null}
            {large ? (
              <div
                className="libpIconStack"
                style={{
                  paddingTop: isMobile ? 20 : 30,
                }}
              >
                {this.props.icons ? (
                  <IconStack icons={this.props.icons} isMobile={isMobile} />
                ) : null}
              </div>
            ) : null}
            <b
              className="libpBotText"
              style={{
                fontSize: small ? 25 : 20,
              }}
            >
              {this.props.text}
              {this.props.info ? <InfoHover text={this.props.info} /> : null}
            </b>
          </Paper>
        )}
      </WidthViewport>
    )
  }
}

export default SmallIconButtonPanel
