import InfoIcon from '@material-ui/icons/Info'
import CameraIcon from '@material-ui/icons/Videocam'
import BatteryFullIcon from '@material-ui/icons/BatteryFull'
import GeneratorTrailerIcon from '../../svg-icons/GeneratorTrailerIcon'
import SkidSteerIcon from '../../svg-icons/SkidSteerIcon'
import LightTowerTrailerIcon from '../../svg-icons/LightTowerTrailerIcon'
import WelderIcon from '../../svg-icons/WelderIcon'
import TruckIcon from '../../svg-icons/TruckIcon'
import MessageBoardIcon from '../../svg-icons/MessageBoardIcon'
import BoxTruckIcon from '../../svg-icons/BoxTruckIcon'
import AirCompressorTrailerIcon from '../../svg-icons/AirCompressorTrailerIcon'
import GreaseTankIcon from '../../svg-icons/GreaseTankIcon'
import RammerIcon from '../../svg-icons/RammerIcon'
import BarrelIcon from '../../svg-icons/BarrelIcon'
import RoundSawIcon from '../../svg-icons/RoundSawIcon'
import MaptracIcon from '../../svg-icons/MaptracIcon'
import RuntimeIcon from '../../svg-icons/RuntimeIcon'
import WaterIcon from '../../svg-icons/WaterIcon'
import FastTruckIcon from '../../svg-icons/FastTruckIcon'
import CarBatteryIcon from '../../svg-icons/CarBatteryIcon'
import DumpTruckIcon from '../../svg-icons/DumpTruckIcon'
import ReachLiftIcon from '../../svg-icons/ReachLiftIcon'
import WaterTruckIcon from '../../svg-icons/WaterTruckIcon'
import SkidTrackIcon from '../../svg-icons/SkidTrackIcon'
import ScissorLiftIcon from '../../svg-icons/ScissorLiftIcon'
import TransmissionTowerIcon from '../../svg-icons/TransmissionTowerIcon'
import CarEmergencyIcon from '../../svg-icons/CarEmergencyIcon'
import ClearIcon from '@material-ui/icons/Clear'

export type IStandardIcons =
  | 'info'
  | 'generator'
  | 'skidSteer'
  | 'lightTowerTrailer'
  | 'welder'
  | 'truck'
  | 'messageBoard'
  | 'boxTruck'
  | 'airCompressorTrailer'
  | 'greaseTank'
  | 'rammer'
  | 'barrel'
  | 'roundSaw'
  | 'maptrac'
  | 'camera'
  | 'battery'
  | 'runtime'
  | 'water'
  | 'turnRight'
  | 'dumptruck'
  | 'reachLift'
  | 'waterTruck'
  | 'skidTrack'
  | 'scissorLift'
  | 'transmissionTower'
  | 'carEmergency'
  | 'close'

/** Standard icons used throughout rovitracker */
export const standardIcons = {
  info: InfoIcon,
  generator: GeneratorTrailerIcon,
  skidSteer: SkidSteerIcon,
  lightTowerTrailer: LightTowerTrailerIcon,
  welder: WelderIcon,
  truck: TruckIcon,
  messageBoard: MessageBoardIcon,
  boxTruck: BoxTruckIcon,
  airCompressorTrailer: AirCompressorTrailerIcon,
  greaseTank: GreaseTankIcon,
  rammer: RammerIcon,
  barrel: BarrelIcon,
  roundSaw: RoundSawIcon,
  maptrac: MaptracIcon,
  camera: CameraIcon,
  battery: CarBatteryIcon,
  runtime: RuntimeIcon,
  water: WaterIcon,
  turnRight: FastTruckIcon,
  dumptruck: DumpTruckIcon,
  reachLift: ReachLiftIcon,
  waterTruck: WaterTruckIcon,
  skidTrack: SkidTrackIcon,
  scissorLift: ScissorLiftIcon,
  transmissionTower: TransmissionTowerIcon,
  carEmergency: CarEmergencyIcon,
  close: ClearIcon,
}
