import React from 'react'
import { TextField as TextFieldMUI } from '@material-ui/core'

interface IState {}

interface IProps {
  label: string
  name?: string
  autoComplete?: string
  type?: string
  value: any
  onChange: (text: string) => void
  required?: boolean
  error?: string
  inputProps?: Object
}

class TextField extends React.Component<IProps, IState> {
  state: IState = {}
  render() {
    return (
      <TextFieldMUI
        required={this.props.required}
        label={this.props.label}
        style={{ width: '100%', maxWidth: 256, height: 60 }}
        value={this.props.value}
        onChange={(event) => this.props.onChange(event.target.value)}
        type={this.props.type}
        name={this.props.name}
        autoComplete={this.props.autoComplete}
        error={!!this.props.error}
        helperText={this.props.error}
        inputProps={this.props.inputProps}
      />
    )
  }
}

export default TextField
