import React from 'react'
import './Styles-CustomerDiscovery/HowManyAssets.css'
import LeadPaper from './LeadPaper'
import SmallIconButtonPanel from './SmallIconButtonPanel'

const HowManyAssetsVal = ['1-20', '21-60', '61+']

type IValues = '1-20' | '21-60' | '61+' | ''

interface IState {
  selected: IValues
}

interface IProps {
  page: number
  onNext: () => void
  onBack: () => void
  changeValue: (val: string) => void
  valueSelected?: string
}

class HowManyAssets extends React.Component<IProps, IState> {
  state: IState = {
    selected: '',
  }

  changeValue(text: IValues) {
    this.setState({ selected: text })
    this.props.changeValue(text)
  }

  componentDidMount() {
    if (this.props.valueSelected) {
      this.setState({ selected: this.props.valueSelected as IValues })
    }
  }

  render() {
    return (
      <LeadPaper
        page={this.props.page}
        TopText="How many assets are you working with?"
        showNext
        showBack
        onNext={this.props.onNext}
        onBack={this.props.onBack}
        disableNext={this.props.valueSelected === ''}
      >
        <div className="hmaBody">
          {HowManyAssetsVal.map((val, key) => (
            <SmallIconButtonPanel
              key={key}
              variant="small"
              text={val}
              value={this.state.selected as IValues}
              onClick={(text: IValues | boolean | string) =>
                this.changeValue(text as IValues)
              }
            />
          ))}
        </div>
      </LeadPaper>
    )
  }
}

export default HowManyAssets
