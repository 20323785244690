import React from 'react'
import Icon from './ux/Icon'
import { IStandardIcons } from './ux/standardIcons'

interface IProps {
  icons: IStandardIcons[]
  isMobile?: boolean
}

class IconStack extends React.Component<IProps> {
  render() {
    const offCenterBottom = this.props.icons.length * -5
    const offCenterLeft = this.props.icons.length * -2
    return (
      <>
        {this.props.icons.length > 1 ? (
          this.props.icons.map((icon, index) => (
            <div
              style={{
                position: 'relative',
                left: index * 5 + offCenterLeft,
                bottom: index * 25 + offCenterBottom,
              }}
              key={index}
            >
              <Icon
                viewSize="0 0 10 10"
                size={this.props.isMobile ? '50px' : '80px'}
                icon={icon}
                color={index % 2 === 0 ? 'primary' : 'secondary'}
              />
            </div>
          ))
        ) : (
          <Icon
            viewSize="0 0 10 10"
            size={this.props.isMobile ? '40px' : '80px'}
            icon={this.props.icons[0]}
            color="primary"
          />
        )}
      </>
    )
  }
}

export default IconStack
