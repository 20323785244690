import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const RuntimeIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M19.7,7.7l1.5-1.5l-1.4-1.4l-1.5,1.5C16.8,5,15,4.3,13,4.1V2h1V0h-4v2h1v2.1C6,4.6,2,8.8,2,14c0,5.5,4.5,10,10,10s10-4.5,10-10C22,11.6,21.2,9.4,19.7,7.7z M12,22c-4.4,0-8-3.6-8-8s3.6-8,8-8c4.4,0,8,3.6,8,8S16.4,22,12,22z M12.8,14.6L12.8,14.6l-2.5,3.1l-1.6-1.3l2-2.4l-3.5-3.7l1.5-1.4l4.1,4.4l0,0c0.2,0.2,0.3,0.4,0.3,0.7C13,14.2,12.9,14.4,12.8,14.6z" />,
  'runtime'
)

export default RuntimeIcon
