import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const BarrelIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M18,19H19V21H5V19H6V13H5V11H6V5H5V3H19V5H18V11H19V13H18V19M9,13A3,3 0 0,0 12,16A3,3 0 0,0 15,13C15,11 12,7.63 12,7.63C12,7.63 9,11 9,13Z" />,
  'barrel'
)

export default BarrelIcon
