import React from 'react'
import './Styles-CustomerDiscovery/ProvideContactInfo.css'
import LeadPaper from './LeadPaper'
import TextField from '../TextField'
import MaskedInput from 'react-text-mask'
import {
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import loadable from '@loadable/component'

const WidthViewport = loadable(() => import('../ux/WidthViewport'))

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      // prettier-ignore
      mask={[/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  )
}

interface IState {}

interface IProps {
  page: number
  onComplete: () => void
  onBack: () => void
  name: string
  company: string
  email: string
  phone: string
  emailError: string
  phoneError: string
  onChangeName: (text: string) => void
  onChangeCompany: (text: string) => void
  onChangeEmail: (text: string) => void
  onChangePhone: (text: string) => void
  findUs: string
  findUsOther: string
  onChangeFindUs: (text: string) => void
  onChangeFindUsOther: (text: string) => void
}

class ProvideContactInfo extends React.Component<IProps, IState> {
  state: IState = {
    buttonSelected: false,
  }
  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <LeadPaper
            page={this.props.page}
            TopText="Please provide us with your contact information."
            showBack
            onComplete={this.props.onComplete}
            onBack={this.props.onBack}
            disableNext={
              !(
                !!this.props.name &&
                !!this.props.company &&
                !!this.props.email &&
                !!this.props.phone &&
                !this.props.emailError &&
                !this.props.phoneError
              )
            }
            // updateHeight={isMobile ? '700' : undefined}
          >
            <div className="pciBody">
              <TextField
                required
                label="Contact Name"
                value={this.props.name}
                onChange={this.props.onChangeName}
              />
              <TextField
                required
                label="Company Name"
                value={this.props.company}
                onChange={this.props.onChangeCompany}
              />
              <TextField
                required
                label="Email Address"
                value={this.props.email}
                onChange={this.props.onChangeEmail}
                error={this.props.emailError}
              />
              <FormControl className="pciPhone">
                <InputLabel required error={!!this.props.phoneError}>
                  Phone Number
                </InputLabel>
                <Input
                  value={this.props.phone}
                  onChange={(event) =>
                    this.props.onChangePhone(event.target.value)
                  }
                  name="textmask"
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom as any}
                  error={!!this.props.phoneError}
                />
                <FormHelperText error={!!this.props.phoneError}>
                  {this.props.phoneError}
                </FormHelperText>
              </FormControl>
              <FormControl style={{ width: isMobile ? 200 : 256 }}>
                <FormLabel>How did you find us? (Optional)</FormLabel>
                <RadioGroup
                  name="findus"
                  value={this.props.findUs}
                  onChange={(event) =>
                    this.props.onChangeFindUs(event.target.value)
                  }
                >
                  <FormControlLabel
                    value="internet search"
                    control={<Radio />}
                    label="Internet Search"
                  />
                  <FormControlLabel
                    value="social media"
                    control={<Radio />}
                    label="Social Media"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
                {this.props.findUs === 'other' ? (
                  <TextField
                    label="Other"
                    value={this.props.findUsOther}
                    onChange={this.props.onChangeFindUsOther}
                  />
                ) : null}
              </FormControl>
            </div>
          </LeadPaper>
        )}
      </WidthViewport>
    )
  }
}

export default ProvideContactInfo
