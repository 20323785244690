import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const TruckIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M4.8,10c0.8-0.1,1.5-0.5,2.1-1.1c0.6-0.6,1.5-1,2.6-1.1c3.8-0.4,5.9-0.4,6.1,0.2l0.3,2H23c0.1,0,0.3,0.3,0.3,0.7c0.1,0.4,0.1,0.8,0.1,1.2l-0.1,0.6v0.1c0.5,0,0.7,0.2,0.7,0.7c0,0.5-0.2,0.7-0.7,0.7h-0.8c-0.2,0-0.7-0.1-1.4-0.2l-0.7,0.2c-0.4-0.9-1.1-1.3-2-1.3c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,0.9-0.6,1.5H6.1c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1,0.2-1.4,0.6s-0.6,0.9-0.7,1.4c-0.8-0.1-1.2-0.3-1.3-0.3c-0.2,0-0.3-0.2-0.3-0.5C0,13.2,0,12.9,0,12.6v-0.5c0.4-0.4,0.6-0.8,0.6-1C0.6,10.8,2,10.4,4.8,10z M2.1,14.6v-0.1c0-0.5,0.2-0.9,0.6-1.2C3,13,3.4,12.8,3.9,12.8c0.5,0,0.9,0.2,1.3,0.5c0.4,0.4,0.5,0.8,0.5,1.3c0,0.5-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.2-0.5C2.3,15.6,2.1,15.1,2.1,14.6z M17.2,15.9c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5c0.8,0,1.3,0.3,1.6,1c0.1,0.2,0.2,0.5,0.2,0.8c0,0.5-0.2,0.9-0.5,1.3c-0.3,0.4-0.8,0.5-1.2,0.5C18,16.4,17.6,16.3,17.2,15.9z" />,
  'truck'
)

export default TruckIcon
