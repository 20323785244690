import React from 'react'
import './Styles-CustomerDiscovery/IndustrySelection.css'
import LeadPaper from './LeadPaper'
import LargeIconButtonPanel from './LargeIconButtonPanel'
import { IStandardIcons } from '../ux/standardIcons'

interface IIndustrySelection {
  text: string
  icons: IStandardIcons[]
  type: string
}

const IndustrySelectionValue: IIndustrySelection[] = [
  {
    text: 'Equipment Rental',
    icons: ['skidTrack', 'scissorLift'],
    type: 'Equipment Rental',
  },
  {
    text: 'Contruction',
    icons: ['reachLift'],
    type: 'Contruction',
  },
  {
    text: 'Energy',
    icons: ['transmissionTower'],
    type: 'Energy',
  },
  {
    text: 'Government',
    icons: ['carEmergency'],
    type: 'Government',
  },
  {
    text: 'Mining',
    icons: ['dumptruck'],
    type: 'Mining',
  },
  {
    text: 'Service',
    icons: ['waterTruck'],
    type: 'Service',
  },
]

interface IState {}

interface IProps {
  page: number
  onNext: () => void
  onAdd?: (str: string) => void
  onRemove?: (str: string) => void
  valuesSelected?: string[]
}

class IndustrySelection extends React.Component<IProps, IState> {
  state: IState = {
    buttonSelected: false,
  }

  updateBox(bool: boolean, str: string) {
    if (bool) {
      this.props.onAdd(str)
    } else {
      this.props.onRemove(str)
    }
  }

  isSelected(val: string) {
    if (this.props.valuesSelected) {
      return this.props.valuesSelected.includes(val)
    }
  }

  render() {
    return (
      <LeadPaper
        page={this.props.page}
        TopText="Which industry are you in?"
        showNext
        onNext={this.props.onNext}
        disableNext={this.props.valuesSelected.length < 1}
      >
        <div className="isBody">
          {IndustrySelectionValue.map((val, key) => (
            <LargeIconButtonPanel
              key={key}
              variant="large"
              text={val.text}
              icons={val.icons}
              onClick={(bool: boolean | string) => {
                this.updateBox(!!bool, val.type)
              }}
              selected={this.isSelected(val.type)}
            />
          ))}
        </div>
      </LeadPaper>
    )
  }
}

export default IndustrySelection
