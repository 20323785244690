import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const CarBatteryIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M4,3V6H1V20H23V6H20V3H14V6H10V3H4M3,8H21V18H3V8M15,10V12H13V14H15V16H17V14H19V12H17V10H15M5,12V14H11V12H5Z" />,
  'carBattery'
)

export default CarBatteryIcon
