import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const LightTowerTrailerIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M8.7,5.5V4.4h10v1.1h-4.5v9.1h8.9v5.5h-3.3c0-1.2-0.4-2.3-1.3-3.1c-0.9-0.9-1.9-1.3-3.1-1.3c-1.2,0-2.3,0.4-3.1,1.3c-0.9,0.9-1.3,1.9-1.3,3.1H5.4V19H1.5c-0.4,0-0.6-0.2-0.6-0.5c0-0.4,0.2-0.6,0.6-0.6h3.9v-3.3h7.8V5.5H8.7z M13.1,3.2c0,0.3-0.1,0.6-0.4,0.9c-0.2,0.2-0.5,0.3-0.9,0.3H8.8c-0.3,0-0.6-0.1-0.9-0.3C7.7,3.8,7.6,3.5,7.6,3.2v-2c0-0.3,0.1-0.6,0.3-0.9C8.2,0.1,8.5,0,8.8,0h3.1c0.3,0,0.6,0.1,0.9,0.3c0.2,0.2,0.4,0.5,0.4,0.9V3.2z M18.1,17.4c-0.7-0.8-1.6-1.1-2.7-1.1c-1.1,0-2,0.4-2.7,1.1c-0.8,0.8-1.1,1.7-1.1,2.7c0,1.1,0.4,2,1.1,2.7c0.8,0.7,1.7,1.1,2.7,1.1c1.1,0,2-0.4,2.7-1.1c0.7-0.7,1.1-1.6,1.1-2.7C19.2,19.1,18.8,18.2,18.1,17.4z M16.6,21.3c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.3c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3C17.1,20.6,16.9,21,16.6,21.3z M19.8,3.2c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.5,0.3-0.9,0.3h-3.1c-0.3,0-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.4-0.9v-2c0-0.3,0.1-0.6,0.4-0.9C14.8,0.1,15.1,0,15.5,0h3.1c0.3,0,0.6,0.1,0.9,0.3c0.2,0.2,0.3,0.5,0.3,0.9V3.2z" />,
  'lightTowerTrailer'
)

export default LightTowerTrailerIcon
