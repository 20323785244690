import React from 'react'
import './Styles-CustomerDiscovery/EndCustomerDiscovery.css'
import LeadPaper from './LeadPaper'
import { Typography } from '@material-ui/core'
import loadable from '@loadable/component'
import { Link } from 'gatsby'

const WidthViewport = loadable(() => import('../ux/WidthViewport'))

export interface IRedirectUserExtras {
  src: string
  text: string
  link: string
}

interface IState {}

interface IProps {
  page: number
  RedirectUserExtras: IRedirectUserExtras[]
}

class EndCustomerDiscovery extends React.Component<IProps, IState> {
  state: IState = {
    buttonSelected: false,
  }

  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <LeadPaper
            page={this.props.page}
            dontShowButtons
            updateHeight={isMobile ? '70vh' : 600}
          >
            <div className="endCustomerDiscoveryBody">
              <Typography
                variant={isMobile ? 'h5' : 'h3'}
                className="ecdTitle"
                style={{
                  margin: isMobile ? 15 : 30,
                }}
              >
                <b>A representative will contact you shortly!</b>
              </Typography>
              <Typography variant={isMobile ? 'subtitle1' : 'h4'}>
                In the meantime, check out some of our products and services
              </Typography>
              <div
                className="ecdExtras"
                style={{
                  height: isMobile ? 400 : '100%',
                  flexWrap: isMobile ? 'wrap' : undefined,
                  overflow: isMobile ? 'auto' : undefined,
                }}
              >
                {this.props.RedirectUserExtras.map((extra, key) => (
                  <Link
                    key={key}
                    to={extra.link}
                    className="container"
                    style={{
                      flexDirection: isMobile ? 'column-reverse' : undefined,
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${extra.src})`,
                      }}
                      className={isMobile ? 'smallImage' : 'largeImage'}
                    ></div>
                    <b
                      className="title"
                      style={{ fontSize: isMobile ? 18 : 20 }}
                    >
                      {extra.text}
                    </b>
                  </Link>
                ))}
                <div
                  className="container"
                  style={{
                    flexDirection: isMobile ? 'column-reverse' : undefined,
                  }}
                >
                  <iframe
                    style={{ margin: 0 }} // style is required here since the iframe doesnt want to respond to classes
                    width={isMobile ? '200px' : '320px'}
                    height={isMobile ? '160px' : '200px'}
                    src="https://www.youtube.com/embed/HXzY_SZ2bE4"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  />
                  <b className="title" style={{ fontSize: isMobile ? 18 : 20 }}>
                    We can help
                  </b>
                </div>
              </div>
            </div>
          </LeadPaper>
        )}
      </WidthViewport>
    )
  }
}

export default EndCustomerDiscovery
