import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const ScissorLiftIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M16.8,2.2v0.4H6.9v1.6H6.5V0.4L7,0h9.7l0.6,0.5v3.7h-0.5V0.7l-0.3-0.3H7.2L6.9,0.7v1.5H16.8z M9.1,22.9c0,0.3-0.1,0.6-0.3,0.8C8.5,23.9,8.2,24,7.9,24c-0.6,0-1-0.3-1.1-0.9L6.7,23c0,0,0-0.1,0-0.1c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.4,0.1,0.7,0.2C9,22.2,9.1,22.5,9.1,22.9z M17.4,6.1V4.2H6.4v1.9h1.3l3.6,1.5L7.8,9L7,8.6L6.8,9.2l0.3,0.1L6.8,9.4L7,9.9l0.8-0.3l3.4,1.5l-3.4,1.4L7,12.1l-0.2,0.5l0.3,0.1l-0.3,0.1L7,13.3L7.8,13l3.4,1.4l-3.7,1.5L7,15.8l-0.2,0.5H6.8L7,16.7l0.5-0.2l3.7,1.6l-4,1.6H6.4v3.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1c0,0.2,0,0.2-0.1,0.3h5.4c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0-0.4,0.2-0.8,0.5-1.1c0.3-0.3,0.7-0.5,1.1-0.5c0.3,0,0.6,0.1,0.9,0.3v-1.8h-1l-3.7-1.6l3.7-1.5l0.4,0.2l0.2-0.5h0.1l-0.2-0.5L16.4,16l-3.7-1.5l3.4-1.4l0.7,0.3l0.2-0.5l-0.3-0.1l0.3-0.1l-0.2-0.5L16,12.5l-3.4-1.4L16,9.7l0.7,0.3L17,9.5l-0.3-0.1l0.3-0.1l-0.2-0.5L16,9.1l-3.4-1.4l3.8-1.5H17.4z M15,19.8H8.6l3.3-1.3L15,19.8z M15.6,16.3l-3.7,1.5l-3.7-1.6l3.7-1.5L15.6,16.3z M15.4,12.8l-3.4,1.4l-3.4-1.4l3.4-1.4L15.4,12.8z M15.4,9.4l-3.4,1.4L8.5,9.3L11.9,8L15.4,9.4z M11.9,7.3L9.1,6.1h6L11.9,7.3z M17.6,22.9c0,0.3-0.1,0.6-0.4,0.8C17,23.9,16.7,24,16.4,24c-0.6,0-1-0.3-1.1-0.9c0,0-0.1-0.1-0.1-0.2c0-0.3,0.1-0.6,0.4-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.2,0,0.5,0.1,0.7,0.2C17.5,22.2,17.6,22.5,17.6,22.9z" />,
  'scissorLift'
)

export default ScissorLiftIcon
