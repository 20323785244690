import React from 'react'
import TypesOfAssets from './TypesOfAssets'
import HowManyAssets from './HowManyAssets'
import DataTypes from './DataTypes'
import ProvideContactInfo from './ProvideContactInfo'
import IndustrySelection from './IndustrySelection'
import EndCustomerDiscovery, {
  IRedirectUserExtras,
} from './EndCustomerDiscovery'
import { RegexEmailCheck } from '../../utility/regex'
import Button from '../Button'
import { RoviLog, ObjErr } from '../../utility/roviLog'

const myHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
}

type TPages = 1 | 2 | 3 | 4 | 5 | 6

interface IState {
  page: TPages
  name: string
  company: string
  email: string
  phone: string
  emailError: string
  phoneError: string
  industry: string[]
  typeOfAssets: string[]
  howManyAssets: string
  dataTypes: string[]
  isLive: boolean
  findUs: string
  findUsOther: string
}

interface IProps {
  theEnd: () => void
}

class FullCustomerDiscoveryComponent extends React.Component<IProps, IState> {
  state: IState = {
    page: 1,
    name: '',
    company: '',
    email: '',
    phone: '',
    industry: [],
    typeOfAssets: [],
    howManyAssets: '',
    dataTypes: [],
    emailError: '',
    phoneError: '',
    isLive: true,
    findUs: '',
    findUsOther: '',
  }

  RedirectUserExtras: IRedirectUserExtras[] = [
    {
      src:
        'https://s3.amazonaws.com/assets.rovitracker.cloud/images/marketing-images/DataCollectionMarketing.jpeg',
      text: 'We Collect Data for You',
      link: 'https://rovitracker.com/features/',
    },
    {
      src:
        'https://s3.amazonaws.com/assets.rovitracker.cloud/images/marketing-images/ServiceMarketing.jpeg',
      text: 'Service and Repair',
      link: 'https://rovitracker.com/features/#tab-1485022678765-5-7',
    },
  ]

  preloadImages(array) {
    let list = []
    for (let i = 0; i < array.length; i++) {
      let img = new Image()
      img.onload = function () {
        let index = list.indexOf(this)
        if (index !== -1) {
          // remove image from the array once it's loaded
          // for memory consumption reasons
          list.splice(index, 1)
        }
      }
      list.push(img)
      img.src = array[i]
    }
  }

  NextPage = () => {
    this.setState({ page: (this.state.page + 1) as TPages })
  }
  PreviousPage = () => {
    this.setState({ page: (this.state.page - 1) as TPages })
  }

  /**
   * Should extract the first and last name of a string, if its a standard name such as.
   * @example "Nate P Young " () => { fn: "Nate", ln: "Young" }
   * @param name give it a string of a full name. "Nate Young"
   */
  splitName(name: string) {
    // Goes through and finds extra white space and removes it, along with trimming the ends
    let splitName = name.replace(/\s+/g, ' ').trim().split(' ')
    // pop is use to grab what ever the last is, and the [0] is meant to just grab the first
    return {
      fn: splitName[0],
      ln: splitName.length === 1 ? '' : splitName.pop(),
    }
  }

  completeform() {
    this.NextPage()
    this.props.theEnd()

    const name = this.splitName(this.state.name)

    let sendingObject = {
      company: this.state.company,
      email: this.state.email,
      fname: name.fn,
      lname: name.ln,
      phone: this.state.phone,
      industry: this.state.industry,
      typeOfAssets: this.state.typeOfAssets,
      howManyAssets: this.state.howManyAssets,
      dataTypes: this.state.dataTypes,
      findUs:
        this.state.findUs === 'other'
          ? this.state.findUsOther
          : this.state.findUs,
    }

    let raw = JSON.stringify(sendingObject)

    this.preloadImages([
      this.RedirectUserExtras[0].src,
      this.RedirectUserExtras[1].src,
    ])

    this.state.isLive
      ? fetch(
          'https://api2.rovitracker.cloud/integrations/hubspot/contact', // switch to api.rovitracker.cloud only for production
          {
            method: 'POST',
            headers: myHeaders,
            body: raw,
          }
        ).catch(() =>
          RoviLog.info(
            ObjErr(
              'Hubspot Failed to send heres the Details of the user:',
              raw
            ),
            'error'
          )
        )
      : RoviLog.info('staging server enabled', 'info')
  }

  changeEmail(val: string) {
    this.setState({
      email: val,
      emailError: RegexEmailCheck.test(val)
        ? ''
        : 'Please enter a valid email address',
    })
  }

  changePhone(val: string) {
    this.setState({
      phone: val,
    })
  }

  switchBetweenComponents() {
    switch (this.state.page) {
      case 1:
        return (
          <IndustrySelection
            page={this.state.page}
            onNext={this.NextPage}
            onAdd={(str: string) => {
              let newArr = this.state.industry
              newArr.push(str)
              this.setState({ industry: newArr })
            }}
            onRemove={(str: string) => {
              let newArr = this.state.industry
              const index = newArr.indexOf(str)
              if (index > -1) {
                newArr.splice(index, 1)
              }
              this.setState({ industry: newArr })
            }}
            valuesSelected={this.state.industry}
          />
        )
      case 2:
        return (
          <TypesOfAssets
            page={this.state.page}
            onNext={this.NextPage}
            onBack={this.PreviousPage}
            onAdd={(str: string) => {
              let newArr = this.state.typeOfAssets
              newArr.push(str)
              this.setState({ typeOfAssets: newArr })
            }}
            onRemove={(str: string) => {
              let newArr = this.state.typeOfAssets
              const index = newArr.indexOf(str)
              if (index > -1) {
                newArr.splice(index, 1)
              }
              this.setState({ typeOfAssets: newArr })
            }}
            valuesSelected={this.state.typeOfAssets}
          />
        )
      case 3:
        return (
          <HowManyAssets
            page={this.state.page}
            onNext={this.NextPage}
            onBack={this.PreviousPage}
            changeValue={(val) => this.setState({ howManyAssets: val })}
            valueSelected={this.state.howManyAssets}
          />
        )
      case 4:
        return (
          <DataTypes
            page={this.state.page}
            onNext={this.NextPage}
            onBack={this.PreviousPage}
            onAdd={(str: string) => {
              let newArr = this.state.dataTypes
              newArr.push(str)
              this.setState({ dataTypes: newArr })
            }}
            onRemove={(str: string) => {
              let newArr = this.state.dataTypes
              const index = newArr.indexOf(str)
              if (index > -1) {
                newArr.splice(index, 1)
              }
              this.setState({ dataTypes: newArr })
            }}
            valuesSelected={this.state.dataTypes}
          />
        )
      case 5:
        return (
          <ProvideContactInfo
            page={this.state.page}
            onComplete={() => this.completeform()}
            onBack={this.PreviousPage}
            name={this.state.name}
            company={this.state.company}
            email={this.state.email}
            phone={this.state.phone}
            emailError={this.state.emailError}
            phoneError={this.state.phoneError}
            onChangeName={(text) => this.setState({ name: text })}
            onChangeCompany={(text) => this.setState({ company: text })}
            onChangeEmail={(text) => this.changeEmail(text)}
            onChangePhone={(text) => this.changePhone(text)}
            onChangeFindUs={(text) => this.setState({ findUs: text })}
            onChangeFindUsOther={(text) => this.setState({ findUsOther: text })}
            findUs={this.state.findUs}
            findUsOther={this.state.findUsOther}
          />
        )
      case 6:
        return (
          <EndCustomerDiscovery
            page={this.state.page}
            RedirectUserExtras={this.RedirectUserExtras}
          />
        )
    }
  }

  componentDidMount() {
    this.setState({
      isLive:
        window.location.hostname === 'discover.rovitracker.com' ? true : false,
    })
  }

  render() {
    return <>{this.switchBetweenComponents()} </>
  }
}

export default FullCustomerDiscoveryComponent
