import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const RoundSawIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M17.2,17.2H5.3c0.2,0.5,0.5,1,0.8,1.5c0,0.1,0.1,0.1,0.2,0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.3-0.4,0.4c-0.2,0-0.4,0-0.6,0.1c0,0,0,0,0,0.1c0.6,0.6,1.2,1.1,2,1.5c0,0,0.1,0,0.1,0c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.1,0.4-0.1,0.5,0.1c0.1,0.2,0.1,0.4-0.1,0.5c-0.2,0.1-0.3,0.2-0.5,0.4c0,0,0,0,0.1,0c0.8,0.2,1.6,0.3,2.4,0.3c0,0,0.1-0.1,0.1-0.1c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.3-0.3,0.5-0.2c0.2,0.1,0.2,0.3,0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c0,0,0,0,0.1,0c0.8-0.2,1.6-0.5,2.2-1c0,0,0.1-0.1,0.1-0.1c0-0.3-0.1-0.6-0.1-1c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.3c0,0.2,0,0.4,0.1,0.6c0,0,0.1,0,0.1,0c0.6-0.6,1.1-1.2,1.4-2c0,0,0-0.1,0-0.2C17.4,17.5,17.3,17.4,17.2,17.2z M23.9,6.3l-0.9-1c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.5,0-0.9-0.4C20.8,4,18.1,3,15.7,2.9c-2.4-0.1-1.2-0.9-3.2-0.3s-3.1,1.8-4,1.5c0,0-3.2-0.1-2.7,1.8c0.4,1.9,2.7-0.3,2.7-0.3l1,0.8C6.6,7,4.3,9.1,3.3,11.7V10H2.5L1,13.8H0.4c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h0.2l-0.5,1.1v0.9h2.6h0.5h18.3v-0.9h-1.4c0-0.3,0-0.8,0-1c0-0.7-0.1-1.4-0.3-2.1c0.2-0.2,0.5-0.3,1,0.1c1,0.8,1.2-0.1,1.2-0.3s0-0.8,0-0.8c0.2,0,0.4-0.2,0.5-0.4l1.3-4.5C24.1,6.6,24.1,6.4,23.9,6.3z M3.3,13.4h2.1c0.6-2.7,3.1-4.8,6-4.8c2.7,0,5.1,1.8,5.9,4.2c-0.2-0.1-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.7,0.1c-0.7-1.8-2.5-3-4.5-3C9,9.9,7,11.5,6.6,13.8c-0.2,0.1-1.5,0.8-1.6,0.7c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.2c0,0.1,0,0.1,0,0.1c0,0.6,0.2,1.1,0.4,1.6H3.3V13.4z M10.1,14.5c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3C10.7,15.8,10.1,15.2,10.1,14.5z M21.1,8.9c-0.4,0.8-1.7,0.9-2.6,0.9c-0.9-1.2-2-2.2-3.3-2.8c0.3-0.4,0.7-0.9,1.1-0.7c0.8,0.4,4.4,0.9,4.4,0.9S21.7,7.7,21.1,8.9z" />,
  'roundSaw'
)

export default RoundSawIcon
