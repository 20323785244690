import React from 'react'
import { standardIcons, IStandardIcons } from './standardIcons'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

interface IIconProps extends SvgIconProps {
  icon: IStandardIcons
  variant?: 'button' | 'tab'
  /** as a number */
  viewSize?: string
  /** as pixels, example: 24px */
  size?: string
  fontSize?: 'small' | 'inherit'
  className?: string
}

/** @Error Ever have an error pointed at this file, check to make sure the icon exists */

/** Icon limited to the rovitracker standard set */
const Icon = ({
  icon,
  color,
  variant,
  viewSize,
  size,
  fontSize,
  className,
  ...props
}: IIconProps) => {
  const C = standardIcons[icon]
  return (
    <SvgIcon
      fontSize={fontSize}
      viewBox={viewSize ? viewSize : undefined}
      style={{
        width: viewSize ? size : '24px',
        height: viewSize ? size : '24px',
        marginRight: variant === 'button' ? 8 : undefined,
      }}
    >
      <C
        color={
          color
            ? color
            : variant === 'button'
            ? 'inherit'
            : variant === 'tab'
            ? 'inherit'
            : 'action'
        }
        {...props}
        className={className}
      />
    </SvgIcon>
  )
}

export default Icon
