import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const ReachLiftIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M14.2,6.9H4.6V6h6.9V0h2.6v3l1.4-0.5l5.8,15.9c1,0.6,1.5,1.5,1.5,2.6c0,0.8-0.3,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.2,0.9c-0.8,0-1.4-0.2-2-0.7c-0.6-0.5-0.9-1.1-1-1.8H7.2c-0.1,0.7-0.4,1.3-1,1.8c-0.6,0.5-1.3,0.7-2,0.7c-0.8,0-1.5-0.3-2.1-0.9c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.7,0.2-1.4,0.7-2c0.5-0.6,1.1-0.9,1.9-1V18h5.6l1.4-6h5.2v6h2.5L14.2,6.2V6.9z M5.5,21c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4C3,20.4,2.8,20.7,2.8,21c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.6,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4C5.3,21.7,5.5,21.4,5.5,21z M21.1,21c0-0.4-0.1-0.7-0.4-0.9c-0.3-0.3-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4c-0.3,0.3-0.4,0.6-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.6,0.4,0.9,0.4c0.4,0,0.7-0.1,0.9-0.4C21,21.7,21.1,21.4,21.1,21z" />,
  'reachLift'
)

export default ReachLiftIcon
