import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const GreaseTankIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M10.2,5c0.5,0,1-0.1,1.4-0.4c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-0.6,0.5-1.5,0.5-2.1,0C9,4,8.9,4.1,8.8,4.2c-0.1,0.1-0.1,0.3,0,0.3C9.3,4.8,9.7,5,10.2,5z M10.2,5.8c0.7,0,1.4-0.2,2-0.7c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-1,0.8-2.4,0.8-3.4,0c-0.1-0.1-0.3-0.1-0.3,0C8.1,4.9,8.1,5,8.2,5.1C8.8,5.5,9.5,5.8,10.2,5.8z M10.2,6.6c0.9,0,1.9-0.3,2.6-0.9c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0c-1.4,1-3.3,1-4.7,0c-0.1-0.1-0.3-0.1-0.3,0c-0.1,0.1-0.1,0.3,0,0.3C8.3,6.3,9.3,6.6,10.2,6.6z M17.9,21.8V3.6c0-1.1-0.9-2-2-2h-4.7V0.7c0-0.4-0.3-0.7-0.7-0.7H10C9.6,0,9.3,0.3,9.3,0.7v0.8H8.2c-1.1,0-2,0.9-2,2v18.2H5.2V24h13.5v-2.2H17.9z M8.2,2.1h1.7v1.3h0.6V2.1h5.3c0.8,0,1.5,0.7,1.5,1.5v8.9c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.2-0.7-0.4-1.4-0.4c-0.7,0-1.1,0.2-1.4,0.4c-0.3,0.1-0.4,0.3-0.9,0.3c-0.4,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.7-0.4-1.4-0.4c-0.7,0-1.1,0.2-1.4,0.4c-0.3,0.1-0.4,0.3-0.9,0.3c-0.4,0-0.6-0.1-0.9-0.3c-0.3-0.1-0.6-0.3-1.1-0.4V3.6C6.7,2.7,7.3,2.1,8.2,2.1z" />,
  'greaseTank'
)

export default GreaseTankIcon
