import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const SkidSteerIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M24,7.4h-1.9l-0.4-3.7H11.1L9.2,9.2H7V12H6.8v-1.2H5.2v3.4H2.4L0,18.1h6.8v-1.2h1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.2,0.9c0.9,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.1v-0.3h1.9v0.3c0,0.9,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.1,0.9c0.9,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2c0-0.3,0-0.5-0.1-0.7l2.2-1V7.4z M12.1,18.5c-0.4,0.4-0.8,0.6-1.3,0.6c-0.5,0-1-0.2-1.3-0.6c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.4-0.4,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.4,0.4,0.5,0.8,0.5,1.3C12.6,17.7,12.4,18.1,12.1,18.5z M9.4,12.9L12,5h6.1v7.9H9.4z M20.1,18.5c-0.4,0.4-0.8,0.6-1.3,0.6c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.4-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.3-0.4,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5c0.4,0.4,0.5,0.8,0.5,1.3C20.6,17.7,20.4,18.1,20.1,18.5z" />,
  'skidSteer'
)

export default SkidSteerIcon
