import React, { useState, ReactElement } from 'react'
import './LoadingOverlay.css'
import { colors } from './roviTheme'
import { WhisperSpinner } from 'react-spinners-kit'

interface IProps {
  loading: boolean | ReactElement<any, any> | null
  delayedTime?: number // ms - default 0
  size?: number
}

export const LoadingIcon: React.FunctionComponent<IProps> = (props) =>
  props.loading ? (
    <WhisperSpinner
      frontColor={colors.primaryBlue}
      backColor={colors.primaryOrange}
      size={props.size ? props.size : 25}
    />
  ) : null

const LoadingOverlay: React.FunctionComponent<IProps> = (props) => {
  const [delayed, setDelayed] = useState(!!props.delayedTime ? true : false)
  setTimeout(
    () => setDelayed(false),
    !!props.delayedTime ? props.delayedTime : 5000
  )
  return delayed ? (
    <div></div>
  ) : props.loading ? (
    <div className="loadingOverlayContainer">
      <div className="spinner">
        <WhisperSpinner
          frontColor={colors.primaryBlue}
          backColor={colors.primaryOrange}
          size={props.size ? props.size : 25}
        />
      </div>
    </div>
  ) : null
}

export default LoadingOverlay
