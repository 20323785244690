import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const GeneratorTrailerIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M11.4,17.4c-0.8-0.8-1.7-1.1-2.7-1.1c-1.1,0-2,0.4-2.7,1.1c-0.8,0.8-1.1,1.7-1.1,2.7c0,1.1,0.4,2,1.1,2.7C6.7,23.6,7.6,24,8.7,24c1.1,0,2-0.4,2.7-1.1c0.8-0.7,1.1-1.6,1.1-2.7C12.6,19.1,12.2,18.2,11.4,17.4z M9.9,21.3c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.2-0.5C7.1,21,7,20.6,7,20.1c0-0.5,0.2-0.9,0.5-1.3c0.3-0.3,0.7-0.5,1.2-0.5c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3C10.5,20.6,10.3,21,9.9,21.3z M12.1,5.4h3.8l-8,7.9l3-5.8H7L14.6,0L12.1,5.4z M18.1,19h0.5v1.1h-5.5c0-1.2-0.4-2.3-1.3-3.1c-0.9-0.9-1.9-1.3-3.1-1.3c-1.2,0-2.3,0.4-3.1,1.3c-0.9,0.9-1.3,1.9-1.3,3.1H0.9v-5.5h17.7v3.3h-0.5c-0.4,0-0.6,0.2-0.6,0.6C17.5,18.9,17.7,19,18.1,19z M23.1,18.5c0,0.3-0.2,0.5-0.6,0.5h-3.9v-1.1h3.9C22.9,17.9,23.1,18.1,23.1,18.5z" />,
  'generatorTrailer'
)

export default GeneratorTrailerIcon
