import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const SkidTrackIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="m 11.06,6.059 c -0.25,0 -0.5,0.197 -0.56,0.439 L 9.26,10.95 c -0.1,0.24 -0.1,0.64 0,0.88 l 0.2,0.8 L 20,9.878 c 0.1,-0.03 0.16,-0.04 0.24,-0.04 0.48,0 0.83,0.382 0.83,0.872 v 1.45 c 0,0.49 -0.37,0.94 -0.86,1.03 l -0.67,0.11 c 0.2,0.14 0.39,0.32 0.51,0.51 l 1.63,2.23 c 0.33,-0.13 0.52,-0.47 0.48,-0.85 L 21.46,8.33 C 21.41,7.827 20.98,7.415 20.5,7.415 h -1.41 l -0.1,-0.901 c 0,-0.25 -0.26,-0.455 -0.51,-0.455 z m 0.79,0.937 h 3.75 c 0.25,0 0.45,0.195 0.48,0.435 l 0.1,1.372 c 0,0.238 -0.16,0.48 -0.38,0.536 l -4.44,1.081 c -0.23,0.1 -0.51,0 -0.6,-0.24 -0.1,-0.193 -0.12,-0.537 0,-0.767 l 0.55,-2 c 0.1,-0.23 0.31,-0.417 0.54,-0.417 z m 8.34,3.464 c 0,0 -0.1,0 -0.11,0 L 7.473,13.68 c -0.225,0.1 -0.586,0.2 -0.749,0.36 l -1.17,1.16 c -0.164,0.15 -0.147,0.4 0.04,0.54 l 0.06,0.1 c 0.09,0.1 0.197,0.1 0.307,0.1 0.123,0 0.246,-0.1 0.337,-0.13 l 1.011,-0.96 c 0.17,-0.15 0.54,-0.27 0.771,-0.31 l 11.98,-1.91 c 0.24,0 0.43,-0.27 0.43,-0.49 v -1.37 c 0,-0.18 -0.13,-0.31 -0.3,-0.31 z m -2.62,3.13 -8.2,1.55 C 8.8,15.25 8.33,15.81 8.33,16.38 v 0.21 c 0,0.58 0.48,1.06 1.06,1.06 h 11.24 c 0.58,0 0.78,-0.38 0.42,-0.85 L 19.23,14.27 C 18.94,13.86 18.34,13.59 17.82,13.59 Z M 5.763,14.5 c -0.15,0 -0.303,0.1 -0.445,0.14 l -3.687,2.42 c -0.428,0.3 -0.359,0.52 0.154,0.52 h 5.128 c 0.513,0 0.743,-0.37 0.51,-0.83 L 6.876,15.67 6.493,16.02 c -0.145,0.14 -0.35,0.22 -0.561,0.22 -0.188,0 -0.368,-0.1 -0.509,-0.17 H 5.364 C 5.203,15.89 5.107,15.72 5.094,15.51 5.084,15.33 5.153,15.12 5.296,14.98 Z m 3.687,1.29 c 0.34,0 0.62,0.27 0.62,0.61 0,0.35 -0.28,0.62 -0.62,0.62 -0.34,0 -0.62,-0.27 -0.62,-0.62 0,-0.34 0.28,-0.61 0.62,-0.61 z" />,
  'skidTrack'
)

export default SkidTrackIcon
