import React from 'react'
import './Styles-CustomerDiscovery/LeadPaper.css'
import { Paper, Typography } from '@material-ui/core'
import IconButton from '../IconButton'
import loadable from '@loadable/component'
import Button from '../Button'

const WidthViewport = loadable(() => import('../ux/WidthViewport'))

interface IState {}

interface IProps {
  TopText?: string
  page: number
  onComplete?: () => void
  onNext?: () => void
  onBack?: () => void
  showBack?: boolean
  showNext?: boolean
  dontShowButtons?: boolean
  updateHeight?: number | string
  disableNext?: boolean
}

class LeadPaper extends React.Component<IProps, IState> {
  render() {
    return (
      <WidthViewport>
        {({ isMobile }) => (
          <Paper
            className="lpContainer"
            style={{
              height: this.props.updateHeight ? this.props.updateHeight : 600,
            }}
          >
            <Typography
              variant={isMobile ? 'caption' : 'h6'}
              className="lpTopBar"
            >
              {this.props.page > 5 ? <div /> : this.props.page + '/5'}
              <IconButton
                style={{ opacity: 0.5 }}
                variant={isMobile ? 'extraSmall' : 'appbar'}
                icon="close"
                href="https://rovitracker.com/"
                tooltip="Close"
              />
            </Typography>
            <Typography variant={isMobile ? 'h6' : 'h4'} className="lpTitle">
              {this.props.TopText}
            </Typography>
            <div
              className="lpBody"
              style={{
                maxHeight: this.props.updateHeight
                  ? this.props.updateHeight
                  : isMobile
                  ? '60vh'
                  : 450,
              }}
            >
              {this.props.children}
            </div>
            {this.props.dontShowButtons ? null : (
              <div className="lpButtonContainer">
                {this.props.showBack ? (
                  <Button onClick={this.props.onBack}>Back</Button>
                ) : (
                  <div />
                )}
                {this.props.showNext ? (
                  <Button
                    color="primary"
                    onClick={this.props.onNext}
                    disabled={this.props.disableNext}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={this.props.onComplete}
                    disabled={this.props.disableNext}
                  >
                    Complete
                  </Button>
                )}
              </div>
            )}
          </Paper>
        )}
      </WidthViewport>
    )
  }
}

export default LeadPaper
