import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const WelderIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M20.4,0.7h-0.9V0h-2.1v0.7H8.5C8.3,0.7,8.2,0.8,8.2,1s0.1,0.3,0.3,0.3h8.9v8.9h0.7v6.6c0,0.8-0.7,1.5-1.5,1.5h-1.1c0-4.2,0-11,0-14.2c0-0.7,0-0.7-0.7-0.7c-3.6,0-7.2,0-10.8,0c-0.7,0-0.7,0-0.7,0.7c0,5.9,0,11.8,0,17.8c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.4,0.4,0.4l0.4,0L4.2,24H6l0.2-1.2c2.3,0,4.1,0,6.5,0l0.2,1.2h1.6l0.2-1.2c0.8-0.1,0.8-0.1,0.8-0.9c0-0.3,0-1.3,0-2.9h1.1c1.2,0,2.2-1,2.2-2.2v-6.6h0.7V1.3h0.9c0.2,0,0.3-0.1,0.3-0.3S20.6,0.7,20.4,0.7z M14.9,22.2c-3.7,0-7.4,0-11.1,0c0-6.1,0-12.1,0-18.1c3.7,0,7.4,0,11.1,0C14.9,10.1,14.9,16.1,14.9,22.2z M18.9,6.5c-0.3,0-0.6,0-1,0c0-1.5,0-2.9,0-4.4c0.3,0,0.6,0,1,0C18.9,3.5,18.9,5,18.9,6.5z M5.8,21.8c2.4,0,4.8,0,7.2,0c1,0,1.5-0.5,1.5-1.5c0-2.1,0-4.2,0-6.3c0-2.1,0-4.3,0-6.4c0-1-0.5-1.5-1.5-1.5c-2.4,0-4.9,0-7.3,0c-0.9,0-1.5,0.5-1.5,1.5c0,4.2,0,8.5,0,12.7C4.2,21.3,4.8,21.8,5.8,21.8z M8.5,19.3H7.6v0.9c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-0.9H6c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h0.9v-0.9c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v0.9h0.9c0.2,0,0.4,0.2,0.4,0.4C8.9,19.2,8.7,19.3,8.5,19.3z M8.5,15.3H6.1c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h2.5c0.2,0,0.4,0.2,0.4,0.4S8.8,15.3,8.5,15.3z M10.5,7.2h3.2c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3h-3.2c-0.2,0-0.3-0.1-0.3-0.3S10.3,7.2,10.5,7.2z M10.5,8.3h3.2c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3h-3.2c-0.2,0-0.3-0.1-0.3-0.3S10.3,8.3,10.5,8.3z M10.5,9.3h3.2c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3h-3.2c-0.2,0-0.3-0.1-0.3-0.3C10.2,9.4,10.3,9.3,10.5,9.3z M5,7.7c0-0.3,0.2-0.5,0.4-0.5h3.8c0.2,0,0.4,0.2,0.4,0.5v1.8c0,0.3-0.2,0.5-0.4,0.5H5.5C5.2,10,5,9.8,5,9.5V7.7z M13.7,19.2c0,0.9-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7S13.7,18.2,13.7,19.2z M13.7,15.3c0,0.9-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7S13.7,14.4,13.7,15.3z" />,
  'welder'
)

export default WelderIcon
