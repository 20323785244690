import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const AirCompressorTrailerIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M4.8,10.5v1.2H0.6c-0.4,0-0.6-0.2-0.6-0.6c0-0.4,0.2-0.6,0.6-0.6H4.8z M4.8,6.9v3.6h0.6c0.4,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6H4.8v1.2h6c0-1.3,0.5-2.5,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4c1.3,0,2.4,0.5,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.4H24v-6H4.8z M19.8,12.9c0,1.2-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2c-1.2,0-2.1-0.4-3-1.2c-0.8-0.8-1.2-1.8-1.2-2.9c0-1.2,0.4-2.1,1.2-3c0.8-0.8,1.8-1.2,3-1.2c1.2,0,2.1,0.4,2.9,1.2C19.4,10.8,19.8,11.8,19.8,12.9z M17.5,12.9c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.8-0.6-1.4-0.6c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.8-0.6,1.4c0,0.5,0.2,1,0.6,1.3c0.4,0.4,0.8,0.5,1.4,0.5c0.5,0,1-0.2,1.4-0.5C17.3,13.8,17.5,13.4,17.5,12.9z" />,
  'airCompressorTrailer'
)

export default AirCompressorTrailerIcon
