import React from 'react'
import FullCustomerDiscoveryComponent from '../components/CustomerDiscovery/FullCustomerDiscoveryComponent'
import Layout from '../components/jsComponents/layout'
import SEO from '../components/jsComponents/seo'
import LoadingOverlay from '../components/ux/LoadingOverlay'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { RoviLog } from '../utility/roviLog'

Sentry.init({
  dsn: 'https://dea50bad80a34f0b8cb17e6e8f459fed@sentry.rovitracker.cloud/21',
  integrations: [new Integrations.BrowserTracing()],
  release: 'marketing-landing-page@1.1.0',
  tracesSampleRate: process.env.NODE_ENV !== 'development' ? 0.25 : 1.0,
  environment:
    process.env.NODE_ENV !== 'development' ? 'production' : 'staging',
})

interface IProps {}

interface IState {
  loading: boolean
  theEnd: boolean
}

class IndexClass extends React.Component<IProps, IState> {
  state: IState = {
    loading: true,
    theEnd: false,
  }

  componentDidMount() {
    // Set here to set a timeout for the average loading time
    // this.setState({ loading: false })
    setTimeout(() => this.setState({ loading: false }), 500)
  }

  render() {
    return (
      <Layout loading={this.state.loading} theEnd={this.state.theEnd}>
        <SEO title="Home" />
        <div style={{ visibility: this.state.loading ? 'hidden' : undefined }}>
          <FullCustomerDiscoveryComponent
            theEnd={() => this.setState({ theEnd: true })}
          />
        </div>
        <LoadingOverlay loading={this.state.loading} />
      </Layout>
    )
  }
}

const IndexPage = () => <IndexClass />

export default IndexPage
