import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const WaterTruckIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M24,3.06v14.26c0,0.15-0.02,0.27-0.06,0.34c-0.04,0.04-0.1,0.13-0.17,0.29c-0.04,0-0.11,0.06-0.23,0.17l-0.11,0.06c-0.08,0-0.13,0-0.17,0c-0.08,0-0.15,0-0.23,0l-0.11,0.06h-0.63c0,0.99-0.34,1.83-1.03,2.52c-0.76,0.69-1.62,1.03-2.58,1.03c-0.99,0-1.83-0.34-2.52-1.03c-0.69-0.69-1.03-1.53-1.03-2.52H9.74c0,0.99-0.34,1.83-1.03,2.52c-0.69,0.69-1.53,1.03-2.52,1.03c-0.95,0-1.81-0.34-2.58-1.03c-0.69-0.69-1.03-1.53-1.03-2.52H1.09c-0.15,0-0.27-0.02-0.34-0.06c-0.08,0-0.15,0-0.23,0l-0.06-0.06C0.38,18.05,0.3,18,0.23,17.96c-0.04-0.04-0.08-0.09-0.11-0.14c-0.04-0.06-0.06-0.1-0.06-0.14C0.02,17.59,0,17.48,0,17.33c0-0.23,0.09-0.44,0.29-0.63c0.15-0.15,0.34-0.23,0.57-0.23v-5.5c0-0.15,0.02-0.31,0.06-0.46c0-0.19,0.04-0.36,0.11-0.52c0-0.04,0.02-0.1,0.06-0.2C1.13,9.7,1.16,9.61,1.2,9.54c0.04-0.11,0.13-0.25,0.29-0.4l2.75-2.75C4.43,6.2,4.66,6.04,4.93,5.93c0.34-0.11,0.63-0.17,0.86-0.17h2.23V3.06c0-0.23,0.08-0.42,0.23-0.57c0.19-0.19,0.4-0.29,0.63-0.29h14.21c0.23,0,0.44,0.1,0.63,0.29C23.9,2.68,24,2.87,24,3.06z M2.63,11.14h5.33V7.59H5.79c-0.08,0-0.19,0.04-0.34,0.11L2.75,10.4c-0.08,0.15-0.11,0.27-0.11,0.34V11.14z M7.96,18.24c0-0.5-0.17-0.92-0.52-1.26c-0.34-0.34-0.76-0.52-1.26-0.52c-0.5,0-0.92,0.17-1.26,0.52c-0.38,0.34-0.57,0.76-0.57,1.26c0,0.5,0.19,0.92,0.57,1.26c0.34,0.34,0.76,0.52,1.26,0.52c0.5,0,0.92-0.17,1.26-0.52C7.79,19.08,7.96,18.66,7.96,18.24z M18.96,11.2c0-1.41-1.15-3.65-3.44-6.7l-0.06-0.11L15.41,4.5c-2.29,3.06-3.44,5.29-3.44,6.7c0,0.99,0.34,1.85,1.03,2.58c0.69,0.73,1.51,1.09,2.46,1.09c0.95,0,1.78-0.36,2.46-1.09C18.62,13.05,18.96,12.19,18.96,11.2z M20.45,18.24c0-0.5-0.17-0.92-0.52-1.26c-0.34-0.34-0.76-0.52-1.26-0.52c-0.5,0-0.94,0.17-1.32,0.52c-0.34,0.34-0.52,0.76-0.52,1.26c0,0.5,0.17,0.92,0.52,1.26c0.38,0.34,0.82,0.52,1.32,0.52c0.5,0,0.92-0.17,1.26-0.52C20.28,19.01,20.45,18.59,20.45,18.24z" />,
  'waterTruck'
)

export default WaterTruckIcon
