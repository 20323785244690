import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const BoxTruckIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M24,3.1v14.3c0,0.2,0,0.3-0.1,0.3c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2,0.1h-0.1h-0.6c0,1-0.3,1.8-1,2.5c-0.8,0.7-1.6,1-2.6,1c-1,0-1.8-0.3-2.5-1c-0.7-0.7-1-1.5-1-2.5H9.7c0,1-0.3,1.8-1,2.5s-1.5,1-2.5,1c-1,0-1.8-0.3-2.6-1c-0.7-0.7-1-1.5-1-2.5H1.1c-0.2,0-0.3,0-0.3-0.1c-0.1,0-0.2,0-0.3-0.1L0.4,18c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2C0,17.6,0,17.5,0,17.3c0-0.2,0.1-0.4,0.3-0.6c0.2-0.2,0.4-0.3,0.6-0.3V11c0-0.2,0-0.3,0.1-0.5c0-0.2,0-0.4,0.1-0.5c0.1-0.3,0.1-0.4,0.2-0.5c0,0,0.1-0.2,0.3-0.5l2.7-2.7C4.4,6.2,4.6,6,4.9,5.9c0.2-0.1,0.5-0.2,0.9-0.2H8V3.1c0-0.2,0.1-0.4,0.2-0.6c0.2-0.2,0.4-0.2,0.6-0.2h14.2c0.3,0,0.5,0.1,0.6,0.2C23.9,2.7,24,2.9,24,3.1zM2.6,11.1H8V7.6H5.8c-0.2,0-0.3,0-0.3,0.1l-2.7,2.7c-0.1,0.2-0.1,0.2-0.1,0.3V11.1z M8,18.2c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5c-0.4,0.4-0.6,0.8-0.6,1.3c0,0.5,0.2,0.9,0.6,1.3C5.3,19.8,5.7,20,6.2,20c0.5,0,0.9-0.2,1.3-0.5C7.8,19.1,8,18.7,8,18.2z M20.4,18.2c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0-0.9,0.2-1.3,0.5c-0.3,0.4-0.5,0.9-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5C20.3,19,20.4,18.6,20.4,18.2z" />,
  'boxTruck'
)

export default BoxTruckIcon
