import React from 'react'
import './Styles-CustomerDiscovery/DataTypes.css'
import LeadPaper from './LeadPaper'
import LargeIconButtonPanel from './LargeIconButtonPanel'
import { IStandardIcons } from '../ux/standardIcons'

interface IDataTypes {
  text: string
  info: string
  icons: IStandardIcons[]
  type: string
}

const DataTypesValue: IDataTypes[] = [
  {
    text: 'GPS',
    info: 'Location, Speed, Motion',
    icons: ['maptrac'],
    type: 'gps',
  },
  {
    text: 'Engine Runtime',
    info: 'Engine start, stop and duration',
    icons: ['runtime'],
    type: 'runtime',
  },
  {
    text: 'Solar Charge / Battery Banks',
    info: 'AC or DC',
    icons: ['battery'],
    type: 'battery',
  },
  {
    text: 'Video',
    info: 'Remote jobsite monitoring',
    icons: ['camera'],
    type: 'video',
  },
  {
    text: 'Fluid Level',
    info: 'Ultrasonic sensor or float level',
    icons: ['water'],
    type: 'fluid level',
  },
  {
    text: 'Behavioral',
    info: 'Speed, Hard stop, Hard brake, Hard turn',
    icons: ['turnRight'],
    type: 'behavioral',
  },
]

interface IState {}

interface IProps {
  page: number
  onNext: () => void
  onBack: () => void
  onAdd?: (str: string) => void
  onRemove?: (str: string) => void
  valuesSelected?: string[]
}

class DataTypes extends React.Component<IProps, IState> {
  state: IState = {
    buttonSelected: false,
  }

  updateBox(bool: boolean, str: string) {
    if (bool) {
      this.props.onAdd(str)
    } else {
      this.props.onRemove(str)
    }
  }

  isSelected(val: string) {
    if (this.props.valuesSelected) {
      return this.props.valuesSelected.includes(val)
    }
  }

  render() {
    return (
      <LeadPaper
        page={this.props.page}
        TopText="What types of data are you interested in?"
        showNext
        showBack
        onNext={this.props.onNext}
        onBack={this.props.onBack}
        disableNext={this.props.valuesSelected.length < 1}
      >
        <div className="dataTypesBody">
          {DataTypesValue.map((val, key) => (
            <LargeIconButtonPanel
              key={key}
              variant="large"
              text={val.text}
              info={val.info}
              icons={val.icons}
              onClick={(bool: boolean | string) =>
                this.updateBox(!!bool, val.type)
              }
              selected={this.isSelected(val.type)}
            />
          ))}
        </div>
      </LeadPaper>
    )
  }
}

export default DataTypes
