import React from 'react'
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

const DumpTruckIcon: React.ComponentType<SvgIconProps> = createSvgIcon(
  <path d="M21.1,6H10.3L7,3.9H2.1v1.4H6L7.1,6H3.5v2.8H2.1v2.1H0.7L0,15.8h1.1c0-0.7,0.3-1.4,0.8-2.1c-0.4,0.6-0.5,1.3-0.5,2.1c0,1.1,0.4,2.1,1.2,3c0.8,0.8,1.8,1.3,2.9,1.3c1.1,0,2.1-0.4,2.9-1.3c0.8-0.8,1.2-1.8,1.2-3c0-1.1-0.4-2-1.1-2.8c0.4,0.3,0.8,0.7,1,1.1c0.3,0.5,0.4,0.8,0.4,1.1v0.5l0.7-0.7c0.1-0.5,0.4-1,0.9-1.5c-0.4,0.6-0.6,1.3-0.6,2.2c0,1.1,0.4,2.1,1.2,3c0.8,0.8,1.8,1.3,2.9,1.3c1.1,0,2.1-0.4,2.9-1.3c0.8-0.8,1.2-1.8,1.2-3c0-0.9-0.3-1.7-0.8-2.4c-0.5-0.7-1.2-1.2-2.1-1.6l1.9-0.2L18.1,10L23,9.2c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.2-0.1L21.1,6z M6.5,16.8c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4c0.3,0.3,0.4,0.6,0.4,1C6.9,16.2,6.7,16.6,6.5,16.8z M7,8.8H4.2V6.7H7V8.8z M16.6,15.8c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.1,1,0.4C16.5,15.1,16.6,15.5,16.6,15.8z" />,
  'dumpTruck'
)

export default DumpTruckIcon
