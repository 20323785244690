import React from 'react'
import './Styles-CustomerDiscovery/TypesOfAssets.css'
import LeadPaper from './LeadPaper'
import LargeIconButtonPanel from './LargeIconButtonPanel'
import { IStandardIcons } from '../ux/standardIcons'

interface IIndustrySelection {
  text: string
  icons: IStandardIcons[]
  type: string
}

const TypesOfAssetsValue: IIndustrySelection[] = [
  {
    text: 'Equipment',
    icons: ['skidSteer', 'lightTowerTrailer'],
    type: 'equipment',
  },
  {
    text: 'Non-Power Trailers',
    icons: ['airCompressorTrailer', 'messageBoard'],
    type: 'trailers',
  },
  {
    text: 'Vehicles',
    icons: ['truck', 'boxTruck'],
    type: 'vehicles',
  },
  {
    text: 'Portable Welders',
    icons: ['welder'],
    type: 'welders',
  },
  {
    text: 'Small tools',
    icons: ['rammer', 'roundSaw'],
    type: 'tools',
  },
  {
    text: 'Fluid Containers',
    icons: ['greaseTank'],
    type: 'fluid',
  },
]

interface IState {}

interface IProps {
  page: number
  onNext: () => void
  onAdd?: (str: string) => void
  onBack: () => void
  onRemove?: (str: string) => void
  valuesSelected?: string[]
}

class TypesOfAssets extends React.Component<IProps, IState> {
  state: IState = {
    buttonSelected: false,
  }

  updateBox(bool: boolean, str: string) {
    if (bool) {
      this.props.onAdd(str)
    } else {
      this.props.onRemove(str)
    }
  }

  isSelected(val: string) {
    if (this.props.valuesSelected) {
      return this.props.valuesSelected.includes(val)
    }
  }

  render() {
    return (
      <LeadPaper
        page={this.props.page}
        TopText="What types of assets are you working with?"
        showNext
        showBack
        onNext={this.props.onNext}
        onBack={this.props.onBack}
        disableNext={this.props.valuesSelected.length < 1}
      >
        <div className="toaBody">
          {TypesOfAssetsValue.map((val, key) => (
            <LargeIconButtonPanel
              key={key}
              variant="large"
              text={val.text}
              onClick={(bool: boolean) => this.updateBox(bool, val.type)}
              icons={val.icons}
              selected={this.isSelected(val.type)}
            />
          ))}
        </div>
      </LeadPaper>
    )
  }
}

export default TypesOfAssets
