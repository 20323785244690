/**
 * This will search through a string making sure it follows standard email guidelines
 */
export const RegexEmailCheck = new RegExp(
  // prettier-ignore
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
)

/**
 * This will Test a Phone Number insuring it follows phone number standards
 */
export const RegexPhoneCheck = new RegExp(
  // '/(^\\+\\d{1,2}\\-|^)\\d{3}\\-\\d{3}\\-\\d{4}/m'
  '^(\\+0?1\\s)?\\(?\\d{3}\\)?[\\s.-]\\d{3}[\\s.-]\\d{4}$'
)
